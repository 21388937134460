/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker";
// tslint:disable:object-literal-key-quotes

export const Transform = t.union(
  t.lit("jsx"),
  t.lit("typescript"),
  t.lit("flow"),
  t.lit("imports"),
  t.lit("react-hot-loader"),
  t.lit("jest"),
);

export const SourceMapOptions = t.iface([], {
  compiledFilename: "string",
});

export const Options = t.iface([], {
  transforms: t.array("Transform"),
  disableESTransforms: t.opt("boolean"),
  jsxRuntime: t.opt(t.union(t.lit("classic"), t.lit("automatic"), t.lit("preserve"))),
  production: t.opt("boolean"),
  jsxImportSource: t.opt("string"),
  jsxPragma: t.opt("string"),
  jsxFragmentPragma: t.opt("string"),
  keepUnusedImports: t.opt("boolean"),
  preserveDynamicImport: t.opt("boolean"),
  injectCreateRequireForImportRequire: t.opt("boolean"),
  enableLegacyTypeScriptModuleInterop: t.opt("boolean"),
  enableLegacyBabel5ModuleInterop: t.opt("boolean"),
  sourceMapOptions: t.opt("SourceMapOptions"),
  filePath: t.opt("string"),
});

const exportedTypeSuite = {
  Transform,
  SourceMapOptions,
  Options,
};
export default exportedTypeSuite;
